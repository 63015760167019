import Textbox from '@borgar/textbox';
import { format } from 'numfmt';

import { getFontStack } from '@grid-is/fontstack';

import { JOIN_E, JOIN_N, JOIN_S, JOIN_W } from './Borders.js';
import { borderStyles } from './borderStyles.js';

const COLOR_TEXT = '#282b3e'; // squid-ink
const COLOR_BORDER = '#282b3e'; // squid-ink
const COLOR_GRID = '#d8d9e5'; // concrete
const COLOR_HEADER_BG = '#f5f5f5'; // cloud
const COLOR_HEADER_GRID = '#d8d9e5'; // concrete

let ctx = null;
const hPadding = 4;
const customHPadding = 8;
const lineHeight = 1.4;

const defaultStyles = {
  'font-name': 'Calibri',
  'font-size': 11,
};

export function setCtx (_ctx) {
  ctx = _ctx;
}

/**
 * @param {null | undefined | import('@grid-is/apiary/lib/csf.js').CellStyle} style
 * @param {string} prop
 * @param {any} [fallback=null] The fallback
 * @return {any} The style property.
 */
function getStyleProp (style, prop, fallback = null) {
  if (style && style[prop]) {
    return style[prop];
  }
  return fallback;
}

function getFontSize (style) {
  return Math.round(getStyleProp(style, 'font-size', 11) * 1.2);
}

/**
 * @param {null | undefined | import('@grid-is/apiary/lib/csf.js').CellStyle} style
 * @return {string}
 */
function getFontShorthand (style) {
  let result = '';
  if (getStyleProp(style, 'italic', false) === true) {
    result += 'italic ';
  }
  if (getStyleProp(style, 'bold', false) === true) {
    result += 'bold ';
  }
  const fs = getFontSize(style);
  result += fs + 'px/' + Math.round(fs * lineHeight) + 'px ';
  const fontName = getStyleProp(style, 'font-name', 'Calibri');
  result += getFontStack(fontName);
  return result;
}

/**
 * @param {Cell | null} cell
 * @return {'left' | 'center' | 'right'}
 */
export function getDefaultAlignment (cell) {
  const v = (cell && typeof cell === 'object') ? cell.v : cell;
  if (v == null || v === '') {
    return 'left';
  }
  else if (typeof v === 'boolean' || v instanceof Error) {
    return 'center';
  }
  else { // num or string
    return typeof v === 'number' ? 'right' : 'left';
  }
}

/**
 * @param {Cell | null} cell
 * @param {string} locale
 * @returns {string}
 */
function getFormattedValue (cell, locale) {
  const v = (cell && typeof cell === 'object') ? cell.v : cell;
  let text = '';
  const type = typeof v;
  if (v == null || v === '') {
    text = '';
  }
  else if (type === 'boolean' || v instanceof Error) {
    text = String(v).toUpperCase();
  }
  else { // num or string
    try {
      text = format((cell ? cell.z : '') || 'General', v, { locale });
    }
    catch (err) {
      text = '#########';
    }
  }
  return text;
}

/**
 * Measures the width of a value in the cell provided
 *
 * @param {Cell | null} cell
 * @param {string} locale
 * @returns {number}
 */
export function measureText (cell, locale) {
  ctx.restore();
  const text = getFormattedValue(cell, locale);
  const styles = cell ? cell.s || defaultStyles : defaultStyles;
  ctx.font = getFontShorthand(styles);
  return ctx.measureText(text).width;
}

/**
 * Calculates the distance between the text & the bottom boundaries of the cell.
 * @param {null | undefined | Record<string, unknown>} style
 * @returns {number}
 */
function getBaseLineDist (style) {
  const fs = getFontSize(style);
  return Math.round(fs * 0.31);
}

/**
 * Draws a line under the cell value provided
 *
 * @param {[ number, number ]} pos
 * @param {number} textWidth: the width of the value in the cell
 * @param {number} width: the width of the cell
 * @param {null | undefined | import('@grid-is/apiary/lib/csf.js').CellStyle} style
 */
function drawUnderline (pos, textWidth, width, style) {
  const underline = getStyleProp(style, 'underline', 'none');
  if (underline !== 'none') {
    const color = getStyleProp(style, 'font-color', COLOR_TEXT);
    const [ x, y ] = pos;

    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 0.7;

    if (underline === 'single') {
      ctx.translate(0, 0.5);
      ctx.moveTo(x, y + 1);
      ctx.lineTo(x + textWidth, y + 1);
    }
    else if (underline === 'singleAccounting') {
      ctx.translate(0, 0.5);
      ctx.moveTo(customHPadding, y + 1);
      ctx.lineTo(customHPadding + width - 2 * customHPadding, y + 1);
    }
    else if (underline === 'double') {
      ctx.moveTo(x, y + 2);
      ctx.lineTo(x + textWidth, y + 2);

      ctx.moveTo(x, y + 0.5);
      ctx.lineTo(x + textWidth, y + 0.5);
    }
    else if (underline === 'doubleAccounting') {
      ctx.moveTo(customHPadding, y + 2);
      ctx.lineTo(customHPadding + width - 2 * customHPadding, y + 2);

      ctx.moveTo(customHPadding, y + 0.5);
      ctx.lineTo(customHPadding + width - 2 * customHPadding, y + 0.5);
    }

    ctx.stroke();
    ctx.closePath();
  }
}

/**
 * returns the coordinates [x,y] which are used as the anchoring point to start drawing the
 * value of the cell
 *
 * @param {string} hAlign: the horizontal alignment of the value in the cell
 * @param {number} width: the width of the cell
 * @param {number} height: the height of the cell
 * @param {number} textWidth: the width of the value in the cell
 * @param {null | undefined | Record<string, unknown>} style
 * @returns [x,y] coordinates which are used as the anchoring point to start drawing on the plane
 */
function getTextAlignment (hAlign, width, height, textWidth, style = defaultStyles) {
  const baseLineDist = getBaseLineDist(style);
  let x = 0;
  let y = 0;
  if (hAlign === 'center') {
    x = width / 2 - textWidth / 2;
    y = height - baseLineDist;
  }
  else if (hAlign === 'right') {
    x = width - textWidth - hPadding;
    y = height - baseLineDist;
  }
  else {
    x = hPadding;
    y = height - baseLineDist;
  }
  return [ x, y ];
}

/**
 * @param {string} text
 * @param {import('./CellRect.js').CellRect} cellRect
 * @param {null | undefined | import('@grid-is/apiary/lib/csf.js').CellStyle} style
 * @param {string} hAlign
 * @param {string} vAlign
 */
function renderWrappedText (text, cellRect, style, hAlign, vAlign) {
  const boxWidth = cellRect.width - hPadding * 2;

  ctx.beginPath();
  ctx.rect(0.5, 0.5, cellRect.width, cellRect.height);
  ctx.clip();

  const fs = getFontSize(style);
  const lh = Math.round(fs * lineHeight);
  const font = getFontShorthand(style || defaultStyles);
  const box = new Textbox({
    width: boxWidth,
    font: font,
    align: hAlign,
    valign: vAlign,
  });
  const lines = box.linebreak(text);

  let baseX = hPadding;
  let baseY = 0;
  // left | right | center
  if (hAlign === 'right') {
    baseX = cellRect.width - lines.width - hPadding;
  }
  else if (hAlign === 'center') {
    baseX = (cellRect.width - lines.width) / 2;
  }
  // top | bottom | center
  if (vAlign === 'bottom') {
    baseY = cellRect.height - lines.height;
  }
  else if (vAlign === 'center') {
    baseY = (cellRect.height - lines.height) / 2;
  }
  // baseline adjustment for first line
  lines.forEach((line, line_nr) => {
    const y = baseY + line_nr * lh + (lh * 0.5);
    let ax = 0;
    if (hAlign === 'right') {
      ax += lines.width - line.width;
    }
    else if (hAlign === 'center') {
      ax += lines.width / 2 - line.width / 2;
    }
    const uy = y + Math.floor(fs * 0.35) + 0.5;
    drawUnderline([ baseX + ax, uy ], line.width, cellRect.width, style);
    ctx.textBaseline = 'middle';
    line.reduce((x, token) => {
      ctx.font = token.font;
      const font = token.font;
      const dy = font.baseline ? (fs * -font.baseline) + (fs * 0.15) : 0;
      ctx.fillText(token.value, x + ax, y + dy);
      return x + token.width;
    }, baseX);
  });
}

/**
 * @param {Cell} cell
 * @param {object} opts
 * @param {import('./CellRect.js').CellRect} opts.cellRect
 * @param {import('./CellRect.js').CellRect} [opts.clipRect]
 * @param {string} [opts.locale]
 */
export function renderText (cell, opts) {
  const cellRect = opts.cellRect;
  const clipRect = opts.clipRect || cellRect;
  const locale = opts.locale || 'en-US';

  ctx.save();

  const styles = cell.s || defaultStyles;
  const text = getFormattedValue(cell, locale);
  const fallbackHorizontalAlignment = getDefaultAlignment(cell);
  if (text) {
    const hAlign = getStyleProp(styles, 'horizontal-alignment', fallbackHorizontalAlignment);
    const vAlign = getStyleProp(styles, 'vertical-alignment', 'bottom');
    ctx.font = getFontShorthand(styles);
    ctx.fillStyle = getStyleProp(styles, 'font-color', COLOR_TEXT);

    if (getStyleProp(styles, 'wrap-text', false)) {
      ctx.translate(cellRect.x, cellRect.y);
      renderWrappedText(text, cellRect, styles, hAlign, vAlign);
    }
    else {
      ctx.translate(clipRect.x, clipRect.y);
      ctx.save();
      ctx.beginPath();
      ctx.rect(0.5, 0.5, clipRect.width, clipRect.height);
      ctx.clip();
      ctx.closePath();

      ctx.textBaseline = 'middle';

      const textWidth = ctx.measureText(text).width;
      const fs = getFontSize(styles);
      const lh = fs * lineHeight;

      let y = (lh * 0.5);
      if (vAlign === 'bottom') {
        y = cellRect.height - (lh * 0.5);
      }
      else if (vAlign === 'center') {
        y = (cellRect.height / 2);
      }

      let x = hPadding;
      if (hAlign === 'center') {
        x = (cellRect.x - clipRect.x) + (cellRect.width / 2) - textWidth / 2;
      }
      else if (hAlign === 'right') {
        x = clipRect.width - textWidth - hPadding;
      }

      ctx.fillText(text, x, y);
      ctx.closePath();

      const uy = y + Math.floor(fs * 0.35) + 0.5;
      drawUnderline([ x, uy ], textWidth, cellRect.width, styles);

      ctx.restore();
    }
  }
  ctx.restore();
}

/**
 * @param {array} lineStart:[x,y] coordinates of where to start drawing the line
 * @param {array} lineEnd: [x,y] coordinates of where to draw the line to
 * @param {string} [strokeStyle]: the color of the line
 * @param {number} [lineWidth=1]: the width of the line
 * @param {array} [lineDash=[]]: an array of numbers that specify wether to draw a line or dotted line https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
 */
function drawLine (lineStart, lineEnd, strokeStyle, lineWidth = 1, lineDash = []) {
  ctx.save();
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle || COLOR_GRID;
  ctx.lineWidth = lineWidth;
  ctx.setLineDash(lineDash);
  ctx.moveTo(lineStart[0], lineStart[1]);
  ctx.lineTo(lineEnd[0], lineEnd[1]);
  ctx.stroke();
  ctx.setLineDash([]);
  ctx.closePath();
  ctx.restore();
}

/**
 * Compute adjustments for double (gapped) borders.
 *
 * @param {number} startJoin Bitmask of directions borders extend from the starting corner.
 * @param {number} endJoin Bitmask of directions borders extend from the ending corner.
 * @param {number} lineWidth The line width.
 * @param {number} gapWidth The line gap width.
 * @param {boolean} isHz Indicates if this is a horizontal border.
 * @return {number[]} adjustments for [ startTop, startBottom, endTop, endBottom ] coords.
 */
function adjustment (startJoin, endJoin, lineWidth, gapWidth, isHz) {
  const x = lineWidth + gapWidth * 0.5;
  const M1 = isHz ? JOIN_N : JOIN_W;
  const M2 = isHz ? JOIN_S : JOIN_E;
  let a0 = lineWidth;
  let a1 = lineWidth;
  let b0 = -lineWidth;
  let b1 = -lineWidth;
  if ((startJoin & M1) && !(startJoin & M2)) {
    a1 = -x;
  }
  else if ((startJoin & M2) && !(startJoin & M1)) {
    a0 = -x;
  }
  else if (!(startJoin & M1) && !(startJoin & M2)) {
    a0 = 0;
    a1 = 0;
  }
  if ((endJoin & M1) && !(endJoin & M2)) {
    b1 = x;
  }
  else if ((endJoin & M2) && !(endJoin & M1)) {
    b0 = x;
  }
  else if (!(endJoin & M1) && !(endJoin & M2)) {
    b0 = 0;
    b1 = 0;
  }
  return [ a0, a1, b0, b1 ];
}

/**
 * @param {[number, number]} lineStart: [x,y] coordinates of where to start drawing the line
 * @param {[number, number]} lineEnd: [x,y] coordinates of where to draw the line to
 * @param {string} [color]: the color of the line
 * @param {import('./borderStyles.js').BorderType} style: the type of border style to use, e.g. dotted, dashed
 * @param {number} [startJoin = 0]
 * @param {number} [endJoin = 0]
 */
export function border (lineStart, lineEnd, style, color, startJoin = 0, endJoin = 0) {
  const borderStyle = borderStyles[style] || borderStyles.thin;
  const lineDash = borderStyle.lineDash || [];
  const lineWidth = borderStyle.lineWidth;
  const slant = borderStyle.slant || 0;
  const gap = borderStyle.gap || (slant ? lineWidth * 0.5 : 0);
  const stroke = color || COLOR_BORDER;
  if (gap || slant) {
    const [ sx, sy ] = lineStart;
    const [ ex, ey ] = lineEnd;
    // horizontal border
    if (sy === ey) {
      // we don't use adjustments for slanted lines because different line lengths mess up the slants
      const [ a0, a1, b0, b1 ] = slant ? [ 0, 0, 0, 0 ] : adjustment(startJoin, endJoin, lineWidth, gap, true);
      drawLine([ sx + slant + a0, sy - gap ], [ ex + slant + b0, ey - gap ], stroke, lineWidth, lineDash);
      drawLine([ sx - slant + a1, sy + gap ], [ ex - slant + b1, ey + gap ], stroke, lineWidth, lineDash);
    }
    // vertical border
    else {
      // we don't use adjustments for slanted lines because different line lengths mess up the slants
      const [ a0, a1, b0, b1 ] = slant ? [ 0, 0, 0, 0 ] : adjustment(startJoin, endJoin, lineWidth, gap, false);
      drawLine([ sx - gap, sy + slant + a0 ], [ ex - gap, ey + slant + b0 ], stroke, lineWidth, lineDash);
      drawLine([ sx + gap, sy - slant + a1 ], [ ex + gap, ey - slant + b1 ], stroke, lineWidth, lineDash);
    }
  }
  else {
    drawLine(lineStart, lineEnd, stroke, lineWidth, lineDash);
  }
}

/**
 * @param {import('./CellRect.js').CellRect} cellRect
 * @param {string} [color]
 */
export function background (cellRect, color) {
  ctx.save();
  ctx.translate(cellRect.x, cellRect.y);
  if (color) {
    ctx.fillStyle = color;
    ctx.fillRect(-0.2, -0.2, cellRect.width + 0.4, cellRect.height + 0.4);
  }
  ctx.restore();
}

/**
 * @param {string} text: the text (row number, column letters) of the header
 * @param {import('./CellRect.js').CellRect} cellRect location and size of the cell
 */
export function header (text, cellRect) {
  ctx.restore();
  ctx.save();
  ctx.translate(cellRect.x, cellRect.y);
  ctx.fillStyle = COLOR_HEADER_BG;
  ctx.fillRect(0, 0, cellRect.width, cellRect.height);
  if (text) {
    ctx.font = getFontShorthand(defaultStyles);
    ctx.fillStyle = COLOR_TEXT;
    const tw = ctx.measureText(text).width;
    const [ x, y ] = getTextAlignment('center', cellRect.width, cellRect.height, tw);

    ctx.fillText(text, x, y);
  }
  ctx.lineWidth = 1;
  ctx.strokeStyle = COLOR_HEADER_GRID;
  ctx.strokeRect(0, 0.5, cellRect.width, cellRect.height);
  ctx.restore();
}

